import { FbTheoryExamEventModel } from "../models/bookings/FbTheoryExamEventModel";

export const HistorySymbol = Symbol("history");
export const UserStoreSymbol = Symbol("userStore");
export const AppStoreSymbol = Symbol("AppStore");
export const AuthServiceSymbol = Symbol("AuthService");
export const FirebaseAccessorSymbol = Symbol("FirebaseAccessor");
export const SettingsSymbol = Symbol("Settings");
export const UserDbServiceSymbol = Symbol("UserDbService");
export const DbServiceSymbol = Symbol("DbService");
export const SignInScreenModelSymbol = Symbol("SignInScreenModel");
export const SignUpScreenModelSymbol = Symbol("SignUpScreenModel");
export const SettingsStoreSymbol = Symbol("SettingsStore");
export const ApiServiceSymbol = Symbol("ApiService");
export const CoreApiServiceSymbol = Symbol("CoreApiService");

export const NativeAppServiceSymbol = Symbol("NativeAppService");
export const HttpServiceSymbol = Symbol("HttpService");
export const ConfirmationFormModelSymbol = Symbol("ConfirmationFormModel");
export const UserModelSymbol = Symbol("UserModel");
export const UserModelFactorySymbol = Symbol("UserModelFactory");
export const InversifyContextSymbol = Symbol("InversifyContext");
export const CalendarModelSymbol = Symbol("CalendarModel");
export const CheckoutScreenModelSymbol = Symbol("CheckoutScreenModel");
export const ConfirmationScreenModelSymbol = Symbol(
  "ConfirmationScreenModelSymbol"
);
export const CoreConfirmationScreenModelSymbol = Symbol(
  "CoreConfirmationScreenModelSymbol"
);
export const DrivingPlanDbServiceSymbol = Symbol("DrivingPlanDbService");
export const OrderModelSymbol = Symbol("OrderModel");
export const LocationModelSymbol = Symbol("LocationModel");
export const AcademyServiceModelSymbol = Symbol("AcademyServiceModel");
export const DatetimeSymbol = Symbol("Datetime");
export const InstructorsScreenModelSymbol = Symbol("InstructorsScreenModel");
export const ServicesScreenModelSymbol = Symbol("ServicesScreenModelSymbol");
export const LessonEventModelSymbol = Symbol("LessonEventModel");
export const FbTheoryExamEventModelSymbol = Symbol("FbTheoryExamEventModel");
export const FbDrivingExamEventModelSymbol = Symbol("FbDrivingExamEventModel");
export const EventModelFactorySymbol = Symbol("EventModelFactory");
export const ScheduleModelSymbol = Symbol("ScheduleModel");
export const ClassEventModelSymbol = Symbol("ClassEventModel");
export const ReScheduleModelSymbol = Symbol("ReScheduleModelSymbol");
export const EventsListScreenModelSymbol = Symbol("EventsListScreenModel");
export const UiStoreSymbol = Symbol("UiStore");
export const DrivingStoreSymbol = Symbol("DrivingStore");
export const I18nServiceSymbol = Symbol("I18nService");
export const DemoFormModelSymbol = Symbol("DemoFormModel");
export const AnalyticsManagerSymbol = Symbol("AnalyticsManager");
export const UserSettingsStoreSymbol = Symbol("UserSettingsStore");
export const ReservePayStoreSymbol = Symbol("ReservePayStore");
export const GiftCardOrderModelSymbol = Symbol("GiftCardOrderModel");
export const GiftCardConfirmationModelSymbol = Symbol(
  "GiftCardConfirmationModel"
);
