import { History } from "history";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { lazyInject } from "../../../inversify/container";
import {
  HistorySymbol,
  SettingsStoreSymbol,
  UserStoreSymbol,
} from "../../../inversify/symbols";
import { ISettingsStore } from "../../../stores/SettingsStore/interfaces";
import { IUserStore } from "../../../stores/UserStore/interfaces";
import { LoadingWrapper } from "../loaders/LoadingWrapper";

export interface IAuthRouteProps extends RouteProps {
  forNotSigned?: true;
  forInstructorsOnly?: true;
  forAll?: true;
}

@observer
export class AuthRoute extends React.Component<IAuthRouteProps> {
  @lazyInject(UserStoreSymbol)
  private readonly userStore!: IUserStore;
  @lazyInject(SettingsStoreSymbol)
  private readonly settingsStore!: ISettingsStore;
  @lazyInject(HistorySymbol)
  private readonly history!: History;

  /**
   * signed in firebase
   */
  public get isAuthenticated(): boolean {
    return !!this.userStore.fbUser;
  }
  /**
   * not only signed in firebase but has full account in our system
   */
  public get isMdaUser(): boolean {
    return !!this.userStore.user;
  }

  public get isInstructor(): boolean {
    return !!this.userStore.user?.isAdmin;
  }
  componentDidMount(): void {
    Promise.all([this.settingsStore.loaded, this.userStore.loaded]).then(() => {
      this.userStore.setInitialRoute(this.props.location!);
    });
  }

  public render() {
    const {
      forNotSigned = false,
      forInstructorsOnly = false,
      forAll = false,
    } = this.props;
    const loadingPage = (
      <LoadingWrapper loading={true}>
        <div style={{ height: "100vh", width: "100vw" }} />{" "}
      </LoadingWrapper>
    );

    if (this.settingsStore.loading) {
      return loadingPage;
    }
    if (this.userStore.loading) {
      return loadingPage;
    }

    if (forAll) {
      return <Route {...this.props} />;
    }

    if (!this.isAuthenticated && !forNotSigned) {
      return (
        <Redirect
          to={{
            pathname: "/account/signin",
            state: this.history.location.pathname.includes("/r/")
              ? this.history.location.pathname
              : {},
          }}
        />
      );
    }

    if (!this.isInstructor && forInstructorsOnly) {
      return <Redirect to={"/booking"} />;
    }
    if (!this.isMdaUser && !forNotSigned) {
      return <Redirect to={"/account/signup"} />;
    }
    if (this.isMdaUser && forNotSigned) {
      const tryingToGetWidgetPage =
        this.history.location.pathname.replace("/", "") === "widget";
      if (tryingToGetWidgetPage) {
        const querySearchString = this.history.location.search.replace("?", "");

        return <Redirect to={`/booking/order?${querySearchString}`} />;
      }
      const navigatedFromTheWidgetPage = this.navigatedFromTheWidgetPage;

      const hasReservationLink = this.history.location.state
        ? (this.history.location.state as string)
        : "/booking";
      sessionStorage.removeItem("fromWidget");
      this.history.replace({ ...this.history.location, state: {} });

      return (
        <Redirect
          to={
            navigatedFromTheWidgetPage ? "/booking/order" : hasReservationLink
          }
        />
      );
    }

    return <Route {...this.props} />;
  }

  public get navigatedFromTheWidgetPage(): boolean {
    const isWidget = sessionStorage.getItem("fromWidget");
    return !!isWidget;
  }
}
