import { History, Location, LocationListener } from "history";
import { interfaces } from "inversify";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { lazyInject } from "../../inversify/container";
import {
  AuthServiceSymbol,
  FirebaseAccessorSymbol,
  HistorySymbol,
  I18nServiceSymbol,
  InversifyContextSymbol,
  NativeAppServiceSymbol,
  OrderModelSymbol,
  SettingsStoreSymbol,
  UiStoreSymbol,
  UserStoreSymbol,
} from "../../inversify/symbols";
import { IAuthService } from "../../services/AuthService/interfaces";
import { II18nService } from "../../services/I18nService/interfaces";
import { INativeAppService } from "../../services/NativeAppService/interfaces";
import { ISettingsStore } from "../../stores/SettingsStore/interfaces";
import { IUiStore } from "../../stores/UiStore/interfaces";
import { IUserStore } from "../../stores/UserStore/interfaces";
import {
  IOrderModel,
  URLS,
} from "../bookings/OrderScreen/OrderForm/interfaces";
import { ScheduleScreen } from "../bookings/ScheduleScreen";
import { RawHtmlContainer } from "../RawHtmlContainer";
import { AuthRoute } from "../shared/AuthRoute";
import { Button } from "../shared/forms/Button";
import { ContentContainer } from "../shared/grid/ContentContainer";
import { ScreenContainer } from "../shared/grid/ScreenContainer";
import { LazyComponent } from "../shared/LazyComponent";
import styles from "./styles.scss";
import type { IFirebaseAccessor } from "@/services/FirebaseAccessor/interfaces";

interface IAppProps extends RouteComponentProps {}
@observer
export class App extends React.Component<IAppProps> {
  @lazyInject(NativeAppServiceSymbol)
  private readonly nativeAppService!: INativeAppService;
  @lazyInject(InversifyContextSymbol)
  private readonly inversifyContext!: interfaces.Context;
  @lazyInject(SettingsStoreSymbol)
  private readonly settingsStore!: ISettingsStore;
  @lazyInject(UiStoreSymbol)
  private readonly uiStore!: IUiStore;
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;
  @lazyInject(AuthServiceSymbol)
  private readonly authService!: IAuthService;
  @lazyInject(HistorySymbol)
  private readonly history!: History;
  @lazyInject(OrderModelSymbol)
  private readonly orderModel!: IOrderModel;
  @lazyInject(UserStoreSymbol)
  private readonly userStore!: IUserStore;
  @lazyInject(FirebaseAccessorSymbol)
  private readonly firebase!: IFirebaseAccessor;
  private locationListenerUnregister: any;
  constructor(props: IAppProps, context?: unknown) {
    super(props, context);
    const getHistoryValue = () => {
      return this.props.history;
    };
    if (!this.inversifyContext.container.isBound(HistorySymbol)) {
      this.inversifyContext.container
        .bind<History>(HistorySymbol)
        .toDynamicValue(getHistoryValue);
    }
  }
  public componentDidMount() {
    if (!URLS.includes(location.pathname)) {
      this.firebase.auth.onAuthStateChanged(async (e) => {
        if (e) {
          this.orderModel.cancelPrevOrder();
        }
      });
    }
    this.locationListenerUnregister = this.history.listen(
      this.locationListener
    );
  }
  public componentWillUnmount() {
    this.inversifyContext.container.unbind(HistorySymbol);
    if (this.locationListenerUnregister) {
      this.locationListenerUnregister();
    }
  }
  public render() {
    const isForAll =
      this.history.location.pathname === "/booking/orderConfirmation" ||
      this.history.location.pathname === "/booking/checkout"
        ? true
        : undefined;
    const userFromWhiteList =
      this.settingsStore.maintenance.whiteList &&
      this.settingsStore.maintenance.whiteList.some(
        (id) => id === this.userStore.fbUser?.uid
      );
    if (this.settingsStore.maintenance.enable && !userFromWhiteList) {
      return (
        <ScreenContainer>
          <ContentContainer>
            <RawHtmlContainer
              content={
                this.settingsStore.maintenance.message || "Under maintenance"
              }
            />
          </ContentContainer>
        </ScreenContainer>
      );
    }

    return (
      <ScreenContainer>
        {this.uiStore.error && (
          <div className={styles.errorContainer}>
            <div className={styles.errorMessage}>
              {this.i18nService.i18next.t("error.message").toString()}
            </div>
            <div className={styles.errorCode}>
              {this.i18nService.i18next.t("error.code").toString()}{" "}
              <span>{this.uiStore.error}</span>
            </div>
          </div>
        )}
        {!this.uiStore.error && (
          <>
            {!this.uiStore.newAppVersionExists && (
              <Switch>
                <AuthRoute path="/account" forNotSigned={true}>
                  <Switch>
                    <Route
                      path="/account/signin"
                      render={this.SignInScreen}
                      exact={true}
                    />
                    <Route
                      path="/account/signup"
                      render={this.SignUpScreen}
                      exact={true}
                    />
                    <Redirect to="/booking" />
                  </Switch>
                </AuthRoute>
                <AuthRoute path="/widget" forNotSigned={true}>
                  <Switch>
                    <Route path="/widget" render={this.DemoScreen} />
                    <Redirect to="/booking" />
                  </Switch>
                </AuthRoute>
                <AuthRoute path="/r">
                  <Switch>
                    <Route
                      path="/r/:bookingId"
                      render={this.ReservePay}
                      exact={true}
                    />
                  </Switch>
                </AuthRoute>
                <AuthRoute path="/booking" forAll={isForAll}>
                  <Switch>
                    <Route
                      path="/booking"
                      render={this.ScheduleScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/info"
                      render={this.InfoScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/view"
                      render={this.EventsListScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/edit"
                      render={this.EventEditScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/terms"
                      render={this.TermsScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/checkout"
                      render={this.CheckoutScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/orderConfirmation"
                      render={this.CoreConfirmationScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/order"
                      render={this.OrderScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/schedule"
                      render={this.ScheduleALessonScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/scheduleConfirm"
                      render={this.ScheduleConfirmScreen}
                      exact={true}
                    />
                    <Route
                      path="/booking/lessonsHistory"
                      render={this.LessonsHistory}
                      exact={true}
                    />
                    <Redirect to="/booking" />
                  </Switch>
                </AuthRoute>
                <AuthRoute path="/giftcard" forAll={true}>
                  <Switch>
                    <Route
                      path="/giftcard"
                      render={this.GiftCardOrderScreen}
                      exact={true}
                    />
                  </Switch>
                </AuthRoute>
                <AuthRoute path={"/chat"} render={this.ChatScreen} />
                <AuthRoute path={"/instructors"} exact={true}>
                  <Route render={this.InstructorsScreen} />
                </AuthRoute>
                <AuthRoute path={"/services"} exact={true}>
                  <Route render={this.ServicesScreen} />
                </AuthRoute>
                <AuthRoute path="/driving" exact={true}>
                  <Route render={this.DrivingScreen} />
                </AuthRoute>
                <Redirect to="/booking" />
              </Switch>
            )}
            {this.uiStore.newAppVersionExists && (
              <ContentContainer>
                {this.i18nService.i18next.t("newVersion").toString()}
                <Button onClick={this.reload}>
                  {this.i18nService.i18next.t("reload").toString()}
                </Button>
              </ContentContainer>
            )}
          </>
        )}
      </ScreenContainer>
    );
  }

  private readonly SignInScreen = () => (
    <LazyComponent
      componentPromise={import(
        /* webpackChunkName: "SignInScreen" */ "../account/SignInScreen"
      ).then((res) => res.SignInScreen)}
    />
  );
  private readonly SignUpScreen = () => (
    <LazyComponent
      componentPromise={import(
        /* webpackChunkName: "SignUpScreen" */ "../account/SignUpScreen"
      ).then((res) => res.SignUpScreen)}
    />
  );
  private readonly DemoScreen = () => (
    <LazyComponent
      componentPromise={import(
        /* webpackChunkName: "DemoScreen" */ "../widget/DemoScreen"
      ).then((res) => res.DemoScreen)}
    />
  );
  private readonly ScheduleScreen = () => {
    console.debug("render ScheduleScreen");

    return <ScheduleScreen />;
  };
  private readonly InfoScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "InfoScreen" */ "../bookings/InfoScreen"
      ).then((res) => res.InfoScreen)}
    />
  );

  private ReservePay = (props) => (
    <LazyComponent
      componentPromise={import(
        /** webpackChunkName: "ReservePay" */ "../bookings/ReservePay"
      ).then((p) => p.ReservePayScreen)}
      {...props}
    />
  );
  private BookingPayedScreen = (props) => (
    <LazyComponent
      componentPromise={import(
        /** webpackChunkName: "ReservePay" */ "../bookings/ReservePay"
      ).then((p) => p.BookingPayedScreen)}
      {...props}
    />
  );
  private readonly EventsListScreen = () => {
    console.debug("render EventsListScreen");
    return (
      <LazyComponent
        componentPromise={import(
          /*  webpackChunkName: "EventsListScreen" */ "../bookings/EventsListScreen"
        ).then((res) => res.EventsListScreen)}
      />
    );
  };
  private readonly EventEditScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "EventEditScreen" */ "../bookings/EventEditScreen"
      ).then((res) => res.EventEditScreen)}
    />
  );
  private readonly TermsScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "TermsScreen" */ "../bookings/TermsScreen"
      ).then((res) => res.TermsScreen)}
    />
  );
  private readonly CheckoutScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "CheckoutScreen" */ "../klarna/CheckoutScreen"
      ).then((res) => res.CheckoutScreen)}
    />
  );
  private readonly CoreConfirmationScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "CoreConfirmationScreen" */ "../klarna/CoreConfirmationScreen"
      ).then((res) => res.ConfirmationScreen)}
    />
  );
  private readonly OrderScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "OrderScreen" */ "../bookings/OrderScreen"
      ).then((res) => res.OrderScreen)}
    />
  );
  private readonly ScheduleALessonScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "ScheduleALessonScreen" */ "../bookings/ScheduleALessonScreen"
      ).then((res) => res.ScheduleALessonScreen)}
    />
  );
  private readonly ScheduleConfirmScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "ScheduleConfirmnScreen" */ "../bookings/ScheduleConfirmScreen"
      ).then((res) => res.ScheduleConfirmScreen)}
    />
  );
  private readonly ChatScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "ChatScreen" */ "../chat/ChatScreen"
      ).then((res) => res.ChatScreen)}
    />
  );
  private readonly InstructorsScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "InstructorsScreen" */ "../instructors/InstructorsScreen"
      ).then((res) => res.InstructorsScreen)}
    />
  );
  private readonly ServicesScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "ServicesScreen" */ "../services/ServicesScreen"
      ).then((res) => res.ServicesScreen)}
    />
  );
  private readonly DrivingScreen = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "DrivingScreen" */ "../driving/DrivingScreen"
      ).then((res) => res.DrivingScreen)}
    />
  );

  private readonly LessonsHistory = () => (
    <LazyComponent
      componentPromise={import(
        /*  webpackChunkName: "LessonsHistory" */ "../bookings/LessonsHistory/index"
      ).then((res) => res.LessonsHistoryList)}
    />
  );

  private readonly GiftCardOrderScreen = () => (
    <LazyComponent
      componentPromise={import(
        /* webpackChunkName: "GiftCardOrderScreen" */ "../giftcard/GiftCardOrderScreen"
      ).then((res) => res.GiftCardOrderScreen)}
    />
  );

  private reload = () => {
    window.location.reload();
  };

  private locationListener: LocationListener = (location: Location) => {
    if (!URLS.includes(location.pathname)) {
      this.orderModel.cancelPrevOrder();
    }

    this.nativeAppService.sendPageLocation(
      `${location.pathname}${location.search}`
    );
  };
}
