import {
  IAcademyServiceModel,
  MotorcycleLessonType,
  VehicleType,
} from "src/models/AcademyServiceModel/interfaces";
import { IReadonlyErrorMessage } from "../../../../models/ErrorMessage";
import {
  FormFieldPendingState,
  FormFieldState,
} from "../../../../models/FormFieldState";
import { IReadonlyPendingStatus } from "../../../../models/PendingStatus";
import {
  IApiAttendee,
  ICartTotalPrice,
  ICorePromoCodeInfo,
  ITimeInterval,
} from "../../../../services/ApiService/interfaces";
import { IClassMeta, IDatePickerOption } from "../../../shared/DatePicker";
import {
  ICityOption,
  IInstructorOption,
  IInstructorSelectOption,
  IRegionOption,
  IServiceOption,
} from "../../../shared/forms/booking/interfaces";
import { ITimePickerOpt } from "../../../shared/forms/TimePickerExtended";
import { IBooking } from "@/services/CoreApiService/interfaces";
import { GearType } from "@/models/InstructorModel/interfaces";
import { seatsWithGearType } from "@/components/shared/forms/interfaces";
import { Language } from "@/services/I18nService/interfaces";

export const LOAD_MORE_SERVICES = {
  value: Symbol("load-more-services"),
  onButtonClick: () => {},
};

export interface IOrderFormMountOptions {
  restoreDisabled?: boolean;
}

export interface IOrderModel {
  readonly regionOptions: IRegionOption[];
  readonly cityOptions: ICityOption[];
  readonly allCityOptions: ICityOption[];
  readonly allServiceOptions: IServiceOption[];
  readonly allRegionOptions: IRegionOption[];
  readonly serviceOptions: IServiceOption[];
  readonly serviceOptionsLocations: IServiceOption[];
  readonly allInstructorOptions: IInstructorOption[];

  readonly instructorOptions: IInstructorOption[];
  readonly options: IInstructorSelectOption[];
  readonly dateOptions: ReadonlyArray<IDatePickerOption<IClassMeta>>;
  readonly checkoutPending: IReadonlyPendingStatus;
  readonly datePickerLoading: IReadonlyPendingStatus;
  readonly loadingTotalPriceStatus: IReadonlyPendingStatus;
  readonly updatingSlotsLeftInClassStatus: IReadonlyPendingStatus;
  readonly timeOptions: ITimePickerOpt[];
  readonly selectedRegion: FormFieldState<IRegionOption | undefined>;
  readonly selectedCity: FormFieldState<ICityOption | undefined>;
  readonly studentRegion: IRegionOption | undefined;
  readonly studentCity: ICityOption | undefined;
  booking: IBooking;
  readonly isVehicleType: VehicleType | undefined;
  readonly selectedVehicleGearType: FormFieldState<GearType | undefined>;
  readonly selectedWheeledVehicle: FormFieldState<VehicleType | undefined>;
  readonly selectedMotorcycleLessonType: FormFieldState<
    MotorcycleLessonType | undefined
  >;

  readonly transmissionMatter: boolean;
  readonly isBundle: boolean;
  readonly selectedService: FormFieldState<IServiceOption | undefined>;
  readonly selectedInstructor: FormFieldState<IInstructorOption | undefined>;
  readonly selectedOption: FormFieldState<IInstructorSelectOption | undefined>;
  readonly selectedDate: FormFieldState<Date | undefined>;
  readonly promoCodeData: ICorePromoCodeInfo | undefined;
  readonly selectedServiceLanguage: Language | undefined;

  readonly datePickerDate: Date;
  readonly selectedTime: FormFieldState<Date | undefined>;
  readonly lessonEndTime: Date;
  //readonly isAutomaticCarPreSelected: boolean;
  readonly isMCPreSelected: GearType | undefined;
  readonly isCarPreSelected: GearType | undefined;
  readonly currentVehicleGearType: GearType | undefined;
  readonly isAutomaticCar: FormFieldState<boolean | null>;
  readonly promoCode: FormFieldPendingState<string | undefined>;
  readonly giftcards: string[];
  readonly giftCardsSum: { name: string; amount: number }[];
  readonly giftcard: FormFieldPendingState<string | undefined>;
  readonly attendeesMode: boolean;
  readonly promoCodeStatus: boolean | undefined;
  readonly lessonsLeft: number;
  readonly attendees: IAttendeesList;
  readonly isFormValid: boolean;
  readonly isFormValidating: boolean;
  readonly somethingWentWrong: boolean | undefined;
  readonly slotsLeftInClass: number; // how many attendees can be added
  readonly seatsInSlot: number;
  readonly seatsLeftInLesson: number;
  readonly qpRestoreWarning: string;
  readonly mcLessonTypeWarning: string;
  readonly errorStatus: IReadonlyErrorMessage;
  readonly coreServices?: IAcademyServiceModel[];
  realLocation?: ICityOption | null;
  cartTotalPrice: ICartTotalPrice | null;
  giftcardAmount: number;
  applyGiftcard(): void;
  deleteGiftcard(name: string): void;
  setSlotsLeftInClass(qty: number): void;
  setSeatsInSlot(seats: number): void;
  setSeatsLeftInLesson(seats: number): void;
  setCoreServices(val: IAcademyServiceModel[]): void;
  setQpRestoreWarning(val: string): void;
  setMcLessonTypeWarning(val: string | undefined): void;
  selectRegion(val: IRegionOption | undefined, setTouched?: boolean): void;
  selectCity(city: ICityOption | undefined, setTouched?: boolean): void;
  selectService(
    service: IServiceOption | undefined,
    setTouched?: boolean
  ): void;
  selectDate(date: Date | undefined, setTouched?: boolean): void;
  changeDatePickerDate(date: Date | undefined): void;
  selectTime(time: Date | undefined, setTouched?: boolean): void;
  setLessonEndTime(time: Date | undefined): void;
  selectServiceLanguage(lng: Language | undefined, setTouched?: boolean): void;
  selectInstructor(
    instructor: IInstructorOption | undefined,
    setTouched?: boolean
  ): void;
  selectOption(
    instructor: IInstructorSelectOption | undefined,
    setTouched?: boolean
  ): void;
  setIsAutomaticCar(val: boolean, setTouched?: boolean): void;
  setVehicleGerType(val: string | undefined, setTouched?: boolean): void;
  selectMotorcycleLessonType(
    val: MotorcycleLessonType | undefined,
    setTouched?: boolean
  ): void;
  setWheeledVehicle(val: VehicleType | undefined, setTouched?: boolean): void;
  setPromo(val?: string, setTouched?: boolean): void;
  setAttendees: (attendees: IApiAttendee[]) => void;
  setGift(val?: string, setTouched?: boolean): void;
  setGiftCardAmount(amount: number): void;
  setGifts(val: string): void;
  getInstructorOptionById(id: number): IInstructorOption | undefined;
  setSomethingWentWrong(val: boolean): void;
  checkout(): Promise<void>;
  pay(): Promise<void>;
  mount(options?: IOrderFormMountOptions): Promise<void>;
  unmount(): void;
  cancelPrevOrder(): void;
}
export interface IGiftcardsWithAmount {
  giftcard: string; //FormFieldPendingState<string | undefined>,
  amount: number;
}

export interface IAttendee {
  // readonly firstName: FormFieldState<string>;
  // readonly lastName: FormFieldState<string>;
  name: FormFieldState<string>;
  readonly ssn: FormFieldState<string>;
  readonly saved: boolean;
  readonly isValid: boolean;
  readonly isOutOfAvailableSlots: boolean;
  readonly savingDisabled: boolean;
  readonly validationPending: boolean;

  addError(error: string): void; // manually makes field being invalid
  requestValidation(): void;
  setSaved(open: boolean): void;
  save(): Promise<void>;
}

export interface IAttendeesList {
  readonly value: ReadonlyArray<IAttendee>;
  readonly isValid: boolean;
  addAttendee(attendee?: IAttendee): void;
  deleteAttendee(attendee: IAttendee): void;
}

export interface IOrderFormStateSaved {
  readonly regionId: number | undefined;
  readonly locationId: number | undefined;
  readonly serviceId: number | undefined;
  readonly instructorId: number | undefined;
  readonly isAutomaticCar?: boolean | null;
  readonly date: number | undefined; // getTime()
  readonly time: number | undefined; // getTime()
  readonly promo: string | undefined;
  readonly attendees: ReadonlyArray<IFormStateAttendee>;
  readonly productType?: string | undefined;
  readonly vehicleType?: string;
  readonly vehicleGearType?: string;
  readonly mcLessonType?: string;
}

export interface IFormStateAttendee {
  name: string;
  ssn: string;
  saved: boolean;
}

export enum ERROR_CODE {
  AttendeeMustBeSaved = "mustBeSaved",
  ThereAreNoEnoughSlots = "thereAreNoEnoughSlots",
}

export interface IIntervalWithMeta {
  dates: ITimeInterval;
  entityId: number;
  availableQty: number;
  isClass: boolean;
  seatsInSlot: number;
}

export interface IIntervalWithWorkload extends IIntervalWithMeta {
  workload: number;
}

export const URLS = [
  "/booking/checkout",
  "/booking/orderConfirmation",
  "/booking/confirmation",
  "/services",
  "/instructors",
];

export interface ErrorMessageCode {
  errorCode: number;
  data: {
    message: string;
  };
}
