import { AxiosError } from "axios";
import { History } from "history";
import { inject, injectable } from "inversify";
import {
  action,
  computed,
  flow,
  IReactionDisposer,
  observable,
  reaction,
  when,
} from "mobx";
import * as moment from "moment";
import { notEmpty } from "../../../../helpers";
import { IDatetime } from "../../../../helpers/Datetime/interfaces";
import {
  ApiServiceSymbol,
  CoreApiServiceSymbol,
  DatetimeSymbol,
  HistorySymbol,
  I18nServiceSymbol,
  SettingsStoreSymbol,
  UserStoreSymbol,
} from "../../../../inversify/symbols";
import {
  IAcademyServiceModel,
  MotorcycleLessonType,
  ProductType,
  VehicleType,
} from "../../../../models/AcademyServiceModel/interfaces";
import { ErrorMessage } from "../../../../models/ErrorMessage";
import {
  GearType,
  IInstructorModel,
  LanguagesSpoken,
  MultipleGearType,
} from "../../../../models/InstructorModel/interfaces";
import { ILocationModel } from "../../../../models/LocationModel/interfaces";
import { PendingStatus } from "../../../../models/PendingStatus";
import { IApiService } from "../../../../services/ApiService/interfaces";
import {
  IBooking,
  ICoreApiService,
} from "../../../../services/CoreApiService/interfaces";
import { II18nService } from "../../../../services/I18nService/interfaces";
import {
  ISettingsStore,
  ServiceKey,
} from "../../../../stores/SettingsStore/interfaces";
import { IUserStore } from "../../../../stores/UserStore/interfaces";
import { IClassMeta, IDatePickerOption } from "../../../shared/DatePicker";
import {
  ICityOption,
  IInstructorOption,
  IInstructorSelectOption,
  IRegionOption,
  IServiceOption,
} from "../../../shared/forms/booking/interfaces";
import { calcServiceOptionType } from "../../../shared/forms/booking/ServiceListOption";
import { ITimePickerOpt } from "../../../shared/forms/TimePickerExtended";
import {
  ANY_EN_INSTRUCTORS_OPT,
  ANY_SV_INSTRUCTORS_OPT,
  dict,
} from "../../../widget/DemoScreen/DemoForm/DemoFormModel";
import {
  IIntervalWithMeta,
  IIntervalWithWorkload,
} from "../../OrderScreen/OrderForm/interfaces";
import { IReScheduleModel } from "./interfaces";

@injectable()
export class ReScheduleModel implements IReScheduleModel {
  public errorMsg = new ErrorMessage();
  @computed
  public get isLockedBooking(): boolean {
    const { booking } = this;
    return !!booking && booking.isLocked;
  }
  @computed
  public get regionOptions(): IRegionOption[] {
    const { user } = this.userStore;

    let options = this.settingsStore.regions
      .slice()
      .filter((r) => r.isVisible)
      .map((location) => {
        return {
          label: location.name[this.i18n.currentLanguage],
          model: location,
          value: location.id,
        };
      });

    if (user) {
      const { profile } = user;
      options = options.filter((option) =>
        profile.availableRegions.find((x) => x === option.value)
      );
    }
    return options;
  }
  @computed
  public get cityOptions(): ICityOption[] {
    const { originalService } = this;

    if (!originalService) {
      return [];
    }
    const { locations } = this.settingsStore;
    const filteredLocations = locations.filter(
      (location) =>
        this.isServiceProvidedInTheLocation(originalService, location) &&
        location.isVisible
    );
    console.debug(
      "locations filtered according to originalService",
      locations,
      filteredLocations
    );
    return filteredLocations.map((location) => {
      return {
        label: location.name[this.i18n.currentLanguage],
        model: location,
        value: location.id,
      };
    });
  }
  @computed
  public get instructorOptions(): IInstructorOption[] {
    const { originalInstructor, selectedService, selectedCity, booking } = this;

    if (!originalInstructor || !selectedService || !selectedCity) {
      return [];
    }
    /* const isAutomaticCar = booking?.vehicle?.gearType === GearType.Automatic;*/
    const { availableInstructorsIds } = selectedCity.model;

    const { availableUnitsIds } = selectedService.model;

    return this.settingsStore.instructors
      .filter((instructor) => {
        return availableInstructorsIds
          ? availableInstructorsIds.find((id) => instructor.id === id)
          : true;
      })
      .filter((instructor) => {
        return availableUnitsIds.find((id) => instructor.id === id);
      })
      .filter((instructor) => {
        /*if (isAutomaticCar) {
          return isAutomaticCar === instructor.isAutomaticCar;
        } else {
          return instructor.isManualCar;
        }*/
        return (
          booking?.vehicle?.gearType &&
          instructor.instructorVehicleGearTypes.includes(
            booking?.vehicle?.gearType
          )
        );
      })
      .sort((a, b) => {
        const posA = a.positionInLocation.get(selectedCity.value);
        const posB = b.positionInLocation.get(selectedCity.value);

        if (typeof posA === "number" && typeof posB === "number") {
          return posA - posB;
        } else {
          return 0;
        }
      })
      .map((instructor) => {
        return {
          label: instructor.name[this.i18n.currentLanguage],
          model: instructor,
          value: String(instructor.id),
        };
      });
  }

  @computed
  public get additionalInstructorOptions(): IInstructorSelectOption[] {
    const anyEnglishInstrOpt =
      this.englishSpeakingInstructors.length > 0
        ? {
            ...ANY_EN_INSTRUCTORS_OPT,
            label: this.i18n.i18next
              .t("order.chooseAnyEngInstructor")
              .toString(),
          }
        : undefined;

    const anySwedishInstrOpt =
      this.englishSpeakingInstructors.length > 0
        ? {
            ...ANY_SV_INSTRUCTORS_OPT,
            label: this.i18n.i18next.t("order.chooseAnyInstructor").toString(),
          }
        : undefined;

    return [anySwedishInstrOpt, anyEnglishInstrOpt].filter(notEmpty);
  }

  @computed
  public get options(): IInstructorSelectOption[] {
    if (!this.instructorOptions.length) {
      return [];
    }
    return [
      ...this.additionalInstructorOptions,
      ...this.instructorOptions.map((opt) => {
        const { model, ...rest } = opt;
        return {
          ...rest,
          picture: model.picture,
        };
      }),
    ];
  }

  @computed
  public get englishSpeakingInstructors(): number[] {
    return this.instructorOptions
      .filter((el) =>
        el.model.languagesSpoken.some((lang) => lang === LanguagesSpoken.EN)
      )
      .map((el) => el.model.id);
  }
  @computed
  public get swedishSpeakingInstructors(): number[] {
    return this.instructorOptions
      .filter((el) =>
        el.model.languagesSpoken.some((lang) => lang === LanguagesSpoken.SV)
      )
      .map((el) => el.model.id);
  }

  @computed
  public get instructorIdsList(): number[] {
    if (this.selectedOption?.value === dict.svVal) {
      return this.swedishSpeakingInstructors;
    } else if (this.selectedOption?.value === dict.enVal) {
      return this.englishSpeakingInstructors;
    } else {
      return [];
    }
  }

  @computed
  public get submitAvailable(): boolean {
    // TODO: isDirty
    return (
      !!this.selectedDate &&
      !!this.selectedTime &&
      !!this.selectedCity &&
      !!this.selectedInstructor
    );
  }
  @computed
  public get dateOptions(): ReadonlyArray<IDatePickerOption<IClassMeta>> {
    const datesSet = new Set<number>();
    const now = Date.now();
    return this.availableInstructorsSlots
      .filter((slot) => {
        const { dates } = slot;
        return dates.from.getTime() - now > 60 * 60 * 1000;
      })
      .map((slot) => {
        return {
          model: {
            disabled: !slot.availableQty,
          },
          value: moment(slot.dates.from).clone().startOf("day").toDate(),
        };
      })
      .filter((date) => {
        const time = date.value.getTime();
        const exists = datesSet.has(time);
        datesSet.add(time);
        return !exists;
      });
  }

  @computed
  public get timeOptions(): ITimePickerOpt[] {
    const { selectedDate, originalDatetime, originalInstructor } = this;
    if (!selectedDate || !originalDatetime || !originalInstructor) {
      return [];
    }
    const now = Date.now();
    const dateTime = selectedDate.getTime();
    return this.availableInstructorsSlots
      .filter((slot) => {
        return slot.dates.from.getTime() > now;
      })
      .filter((slot) => {
        return (
          moment(slot.dates.from).clone().startOf("day").toDate().getTime() ===
          dateTime
        );
      })
      .sort((a, b) => a.dates.from.valueOf() - b.dates.from.valueOf())
      .map((slot) => ({
        availableQty: slot.availableQty,
        date: slot.dates.from,
        entityId: slot.entityId,
        endTime: slot.dates.to,
        isClass: false,
        seatsInSlot: slot.seatsInSlot,
      }));
  }
  @computed
  public get originalInstructor(): IInstructorModel | undefined {
    const { booking } = this;
    if (booking) {
      return this.settingsStore.instructors.find(
        (x) => booking.instructorId && x.id === booking.instructorId
      );
    } else {
      return undefined;
    }
  }

  @computed
  private get originalCity(): ILocationModel | undefined {
    const { booking } = this;
    if (!booking) {
      return undefined;
    }
    const { locationId } = booking;
    return locationId
      ? this.settingsStore.locations.find((x) => x.id === locationId)
      : undefined;
  }

  @computed
  private get originalRegionId(): number | undefined {
    const { booking } = this;
    if (!booking) {
      return undefined;
    }
    const { locationId } = booking;
    return locationId
      ? this.settingsStore.locations.find((x) => x.id === locationId)?.regionId
      : undefined;
  }

  @computed
  private get carType(): GearType | undefined {
    return this.userStore.user?.profile.isAutomaticCar
      ? GearType.Automatic
      : GearType.Manual;
  }
  @computed
  public get originalDatetime(): Date | undefined {
    const { booking } = this;
    if (!booking) {
      return undefined;
    }
    const { startDate } = booking;
    const bookingDatetime = this.datetime.fromDateTimeString(startDate);
    if (!bookingDatetime) {
      console.error(`Wrong time format "${startDate}"`);
      return undefined;
    } else {
      return bookingDatetime.toDate();
    }
  }
  public datePickerLoading = new PendingStatus();
  public submittingStatus = new PendingStatus();
  @observable
  public selectedDate: Date | undefined;
  @observable
  public selectedRegion: IRegionOption | undefined;
  @observable
  public selectedCity: ICityOption | undefined;
  @observable
  public selectedInstructor: IInstructorOption | undefined;
  @observable
  public selectedOption: IInstructorSelectOption | undefined;
  @observable
  public selectedService: IServiceOption | undefined;
  @observable
  public selectedMotorcycleLessonType: MotorcycleLessonType | undefined;
  @observable
  public datePickerDate: Date = new Date();
  @observable
  public selectedTime: Date | undefined;
  @observable
  public lessonEndTime: Date | undefined;
  @observable
  public qtySeats: number = 0;
  @observable
  public seatsInSlot = 0;

  private selectableDatesDisposer: IReactionDisposer | undefined;
  @observable
  private booking: IBooking | undefined;
  @observable
  private originalService: IAcademyServiceModel | undefined;
  private availableInstructorsSlots = observable.array<IIntervalWithMeta>();
  private initData = flow(function* (this: ReScheduleModel, bookingId: string) {
    const booking: IBooking | undefined = yield this.coreApiService.getBooking(
      bookingId
    );
    if (!booking) {
      throw new Error("Booking not found");
    }
    this.setBooking(booking);

    const coreService = this.settingsStore.services.find(
      (service) =>
        service.id === booking.serviceId &&
        service.productType === ProductType.Service
    );
    this.setOriginalService(coreService);

    const {
      originalInstructor,
      originalCity,
      originalService,
      originalRegionId,
    } = this;

    if (
      !originalInstructor ||
      !originalCity ||
      !originalService ||
      !originalRegionId
    ) {
      throw new Error("Can't find instructor, city or service");
    }
    const bookingDatetime = this.datetime.fromDateTimeString(booking.startDate);
    if (!bookingDatetime) {
      throw new Error(`Wrong time format "${booking.startDate}"`);
    }

    const regionOption = this.regionOptions.find(
      (x) => x.value === originalRegionId
    );
    if (regionOption) {
      this.selectRegion(regionOption);
    } else {
      console.error("Region option is not found from the start");
    }
    const cityOption = this.cityOptions.find(
      (x) => x.value === originalCity.id
    );

    if (cityOption) {
      this.selectCity(cityOption);
    } else {
      console.error("City option is not found from the start");
    }
    const serviceOption: IServiceOption = {
      label: originalService.name[this.i18n.currentLanguage],
      model: originalService,
      type: calcServiceOptionType(originalService),
      value: originalService.id,
      isClass: originalService.isClass,
    };

    if (serviceOption) {
      this.selectService(serviceOption);
    } else {
      console.error("Service option is not found from the start");
    }

    const option = this.options.find(
      (x) => Number(x.value) === originalInstructor.id
    );

    if (
      serviceOption.model.vehicleType !== VehicleType.mc ||
      (serviceOption.model.vehicleType === VehicleType.mc && this.isNewbie)
    ) {
      if (option) {
        this.selectOption(option);
      } else {
        console.error("Instructor option is not found from the start");
      }
    }
    this.selectDate(bookingDatetime.clone().startOf("day").toDate());
    this.selectTime(bookingDatetime.toDate());
  }).bind(this);
  constructor(
    @inject(ApiServiceSymbol) private readonly apiService: IApiService,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService,

    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(HistorySymbol) private readonly history: History,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore,
    @inject(DatetimeSymbol) private readonly datetime: IDatetime,
    @inject(I18nServiceSymbol) private readonly i18n: II18nService
  ) {}
  public async mount(bookingId: string) {
    const { user } = this.userStore;
    if (!user) {
      throw new Error("User must be authenticated");
    }
    await user.refreshProfile();
    await when(() => !user.profileReloading.isPending);

    this.selectableDatesDisposer = reaction(
      () => ({
        booking: this.booking,
        datePickerDate: this.datePickerDate,
        selectedInstructor: this.selectedOption,
        selectedLocation: this.selectedCity,
        selectedService: this.selectedService,
      }),
      ({
        datePickerDate,
        selectedService,
        selectedInstructor,
        booking,
        selectedLocation,
      }) => {
        this.updateSelectableDates(
          booking,
          datePickerDate,
          !!this.instructorIdsList.length
            ? this.instructorIdsList
            : selectedInstructor && [Number(selectedInstructor.value)],
          selectedService && selectedService.model,
          selectedLocation?.value
        ).catch((err) => console.error(err));
      }
    );
    await this.initData(bookingId);
  }
  public unmount() {
    if (this.selectableDatesDisposer) {
      this.selectableDatesDisposer();
    }
  }

  @action
  public selectDate(date: Date | undefined) {
    this.selectedDate = date;
  }
  @action
  public changeDatePickerDate(date: Date) {
    this.datePickerDate = date;
  }

  @action
  public selectTime(time: Date | undefined) {
    this.selectedTime = time;
  }

  @action
  public setQtySeats(qty: number) {
    this.qtySeats = qty;
  }
  @action
  public selectMotorcycleLessonType(val: MotorcycleLessonType | undefined) {
    this.selectedMotorcycleLessonType = val;
  }

  public async reSchedule() {
    if (this.submittingStatus.isPending) {
      console.warn("reSchedule already in process");
      return;
    }
    const user = this.userStore.user!;
    const booking = this.booking!;

    const { selectedDate, selectedTime, selectedInstructor, selectedCity } =
      this;
    if (!selectedInstructor || !selectedCity) {
      throw new Error(" Instructor or city is not selected");
    }
    if (!selectedDate || !selectedTime) {
      throw new Error(" Time is not selected");
    }
    if (!booking?.vehicle?.gearType) {
      throw new Error("Gear type is missing");
    }

    try {
      this.errorMsg.clear();
      this.submittingStatus.startPending();
      await this.coreApiService.editBook({
        bookingId: booking.id,
        clientId: user.id,
        date: this.datetime.toDateString(selectedDate),
        instructorId: selectedInstructor.model.id,
        locationId: selectedCity.value,
        serviceId: booking.serviceId,
        time: this.datetime.toTimeString(selectedTime),
        vehicleGearType: booking.vehicle.gearType,
        mcLessonType: this.selectedMotorcycleLessonType,
      });
      this.history.push("/booking"); // TODO: /booking/view
    } catch (e) {
      // FIXME
      const errorData: any = e;
      const errorCode = errorData.errorCode;
      const error = errorData.error;
      let errorMsg = "";
      if (errorCode === 1116) {
        errorMsg = this.i18n.i18next.t(`errors.1116`).toString();
      } else errorMsg = `Error: ${error}`;

      this.errorMsg.setMessage(errorMsg);
    } finally {
      this.submittingStatus.stopPending();
    }
  }
  @action
  public selectInstructor(instructor: IInstructorOption | undefined) {
    this.selectedInstructor = instructor;
  }

  @action
  public selectOption(instructor: IInstructorSelectOption | undefined) {
    if (!!instructor) {
      const instructorOption = this.instructorOptions.find(
        (x) => x.model.id === Number(instructor.value)
      );

      this.selectInstructor(instructorOption);
    }

    this.selectedOption = instructor;
  }
  @action
  public setSeatsInSlot(val: number) {
    this.seatsInSlot = val;
  }

  public getInstructorOptionById(id: number): IInstructorOption | undefined {
    const instructor = this.settingsStore.instructors.find(
      (currentInstructor) => id === currentInstructor.id
    );

    return !!instructor ? this.instructorToOption(instructor) : undefined;
  }

  @action
  public selectService(service: IServiceOption | undefined) {
    this.selectedService = service;
    const { selectedInstructor } = this;
    if (!service) {
      console.debug("Service unset, instructor field cleared");
      this.selectInstructor(undefined);
    } else if (selectedInstructor) {
      const { availableUnitsIds } = service.model;
      const instructorSupportsNewService = availableUnitsIds.some(
        (unitId) => unitId === selectedInstructor.model.id
      );
      if (!instructorSupportsNewService) {
        console.debug(
          "Selected instructor does not support selected service (or not available in this location). Unset instructor field "
        );
        this.selectInstructor(undefined);
      }
    }
  }
  @action
  public selectRegion(region: IRegionOption | undefined) {
    this.selectedRegion = region;
  }
  @action
  public selectCity(city: ICityOption | undefined) {
    this.selectedCity = city;
    const { originalService } = this;
    if (originalService) {
      const newServiceModel = {
        label: originalService.name[this.i18n.currentLanguage],
        model: originalService,
        type: calcServiceOptionType(originalService),
        value: originalService.id,
        isClass: originalService.isClass,
      };
      this.selectService(newServiceModel);
    }
  }
  @action
  public setLessonEndTime(time: Date) {
    this.lessonEndTime = time;
  }
  @computed
  public get hasMcGearType() {
    if (this.booking?.vehicle?.gearType === GearType.McA) {
      return true;
    }
    if (this.booking?.vehicle?.gearType === GearType.McA2) {
      return true;
    }
    if (this.booking?.vehicle?.gearType === GearType.McA1) {
      return true;
    }
  }
  @computed
  public get isNewbie() {
    const isNewbie = this.userStore.user?.isNewbie;
    if (isNewbie) return true;
    else return false;
  }

  private isServiceProvidedInTheLocation(
    service: IAcademyServiceModel,
    location: ILocationModel
  ): boolean {
    return location.availableServicesIds.some((id) => service.id === id);
  }
  @action
  private replaceAvailableInstructorSlots(val: IIntervalWithMeta[]): void {
    this.availableInstructorsSlots.replace(val);
    const { selectedDate } = this;
    if (selectedDate) {
      const dateAvailable = this.dateOptions.some(
        (option) => option.value.valueOf() === selectedDate.valueOf()
      );
      if (!dateAvailable) {
        console.debug(
          "Selected day is not available for selected instructor, unset the day and time"
        );
        this.selectDate(undefined);
        this.selectTime(undefined);
      } else {
        const { selectedTime } = this;
        if (selectedTime) {
          const timeAvailable = this.timeOptions.some(
            (option) => option.date.valueOf() === selectedTime.valueOf()
          );
          if (!timeAvailable) {
            console.debug(
              "Selected time is not available for selected instructor, unset the time"
            );
            this.selectTime(undefined);
          }
        }
      }
    }
  }

  private async updateSelectableDates(
    booking: IBooking | undefined,
    datePickerDate: Date,
    selectedInstructorsIds?: number[],
    service?: IAcademyServiceModel,
    locationId?: number
  ) {
    if (!booking || !service || !locationId) {
      this.replaceAvailableInstructorSlots([]);
      return;
    }
    try {
      const isNewbie = this.userStore.user?.isNewbie;

      this.datePickerLoading.startPending();
      const isFulfilled = <T>(
        p: PromiseSettledResult<T>
      ): p is PromiseFulfilledResult<T> => p.status === "fulfilled";

      const from = moment(datePickerDate)
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      const to = moment(datePickerDate)
        .clone()
        .endOf("month")
        .format("YYYY-MM-DD");

      const isClass = !!service && service.isClass;

      if (isClass) {
        const slots = await this.coreApiService.getAvailableClassesSlots(
          from,
          to,
          service.id
        );

        const mappedSlots = slots.map((slot) => {
          return {
            availableQty: slot.qty,
            dates: { ...slot.interval },
            entityId: service.id,
            isClass,
            seatsInSlot: slot.seats,
          };
        });

        this.replaceAvailableInstructorSlots(mappedSlots);
      } else {
        if (!!selectedInstructorsIds?.length) {
          /* const hasMultipleGearType =
            booking.vehicle?.gearType &&
            [GearType.McA2, GearType.McA].includes(booking.vehicle?.gearType) &&
            service.meta?.key !== ServiceKey.DrivingTest;*/

          const result = await Promise.allSettled(
            selectedInstructorsIds.map(async (instructorId) => {
              try {
                const slots = await this.coreApiService.getAvailableSlots(
                  from,
                  to,
                  instructorId,
                  service.id,
                  isClass,
                  /*hasMultipleGearType
                    ? MultipleGearType.McAMcA2
                    :*/ booking.vehicle?.gearType,
                  locationId,
                  this.hasMcGearType && isNewbie
                    ? MotorcycleLessonType.Lesson
                    : this.selectedMotorcycleLessonType,
                  this.hasMcGearType && this.isNewbie ? 1 : undefined,
                  this.hasMcGearType ? booking.clientId : undefined
                );
                const workload =
                  await this.coreApiService.getInstructorWorkload(
                    from,
                    to,
                    instructorId
                  );

                return slots.map((slot) => ({
                  availableQty: slot.qty,
                  dates: { ...slot.interval },
                  entityId: instructorId,
                  isClass: false,
                  seatsInSlot: slot.seats,
                  workload,
                }));
              } catch (e) {
                console.error(e);
                return [];
              }
            })
          );
          const dataDict: {
            [key: string]: IIntervalWithWorkload;
          } = {};

          const fulfilledValues = result
            .filter(isFulfilled)
            .map((p) => p.value);

          fulfilledValues
            .reduce((acc, val) => acc.concat(val), [])
            .forEach((el) => {
              const { dates } = el;
              const key = dates.from.getTime();

              if (dataDict.hasOwnProperty(key)) {
                if (dataDict[key].workload >= el.workload) {
                  dataDict[key] = el;
                }
              } else {
                dataDict[key] = el;
              }
            });

          const availableTimeSlots: IIntervalWithMeta[] = Object.values(
            dataDict
          ).map((el) => {
            const { workload, ...res } = el;
            return res;
          });

          this.replaceAvailableInstructorSlots(availableTimeSlots);
        } else {
          this.replaceAvailableInstructorSlots([]);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.datePickerLoading.stopPending();
    }
  }

  private instructorToOption(instructor: IInstructorModel): IInstructorOption {
    return {
      label: instructor.name[this.i18n.currentLanguage],
      model: instructor,
      value: String(instructor.id),
    };
  }

  @action
  private setBooking(booking: IBooking) {
    this.booking = booking;
  }
  @action
  private setOriginalService(val?: IAcademyServiceModel) {
    this.originalService = val;
  }
}
