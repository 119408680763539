import { ContainerModule, interfaces } from "inversify";
import { IGiftCardOrderModel } from "../components/giftcard/interfaces";
import { GiftCardOrderModel } from "../components/giftcard/model";
import { IConfirmationFormModel } from "../components/account/ConfirmationForm/interfaces";
import { ConfirmationFormModel } from "../components/account/ConfirmationForm/model";
import { ISignInScreenModel } from "../components/account/SignInScreen/interfaces";
import { SignInScreenModel } from "../components/account/SignInScreen/model";
import { ISignUpScreenModel } from "../components/account/SignUpScreen/interfaces";
import { SignUpScreenModel } from "../components/account/SignUpScreen/model";
import { IReScheduleModel } from "../components/bookings/EventEditScreen/ReScheduleForm/interfaces";
import { ReScheduleModel } from "../components/bookings/EventEditScreen/ReScheduleForm/ReScheduleModel";
import { EventsListScreenModel } from "../components/bookings/EventsListScreen/EventsListScreenModel";
import { IEventsListScreenModel } from "../components/bookings/EventsListScreen/interfaces";
import { IOrderModel } from "../components/bookings/OrderScreen/OrderForm/interfaces";
import { OrderModel } from "../components/bookings/OrderScreen/OrderForm/OrderModel";
import { IScheduleModel } from "../components/bookings/ScheduleALessonScreen/ScheduleForm/interfaces";
import { ScheduleModel } from "../components/bookings/ScheduleALessonScreen/ScheduleForm/ScheduleModel";
import { ICalendarModel } from "../components/bookings/ScheduleScreen/Calendar/interfaces";
import { CalendarModel } from "../components/bookings/ScheduleScreen/Calendar/model";
import { InstructorsScreenModel } from "../components/instructors/InstructorsScreen/InstructorsScreenModel";
import { IInstructorsScreenModel } from "../components/instructors/InstructorsScreen/interfaces";
import { ICheckoutScreenModel } from "../components/klarna/CheckoutScreen/interfaces";
import { CheckoutScreenModel } from "../components/klarna/CheckoutScreen/model";
import { ICoreConfirmationScreenModel } from "../components/klarna/CoreConfirmationScreen/interfaces";
import { CoreConfirmationScreenModel } from "../components/klarna/CoreConfirmationScreen/model";
import { IServicesScreenModel } from "../components/services/ServicesScreen/interfaces";
import { ServicesScreenModel } from "../components/services/ServicesScreen/ServicesScreenModel";
import { DemoFormModel } from "../components/widget/DemoScreen/DemoForm/DemoFormModel";
import { IDemoFormModel } from "../components/widget/DemoScreen/DemoForm/interfaces";
import { Datetime } from "../helpers/Datetime";
import { IDatetime } from "../helpers/Datetime/interfaces";
import {
  AcademyServiceModel,
  IServiceData,
} from "../models/AcademyServiceModel";
import { ClassEventModel } from "../models/bookings/ClassEventModel";
import { EventModelFactory } from "../models/bookings/EventModelFactory";
import { FbDrivingExamEventModel } from "../models/bookings/FbDrivingExamEventModel";
import { FbTheoryExamEventModel } from "../models/bookings/FbTheoryExamEventModel";
import {
  IClassEventModel,
  IEventModelFactory,
  IFbDrivingExamEventModel,
  IFbTheoryExamEventModel,
  ILessonEventModel,
} from "../models/bookings/interfaces";
import { LessonEventModel } from "../models/bookings/LessonEventModel";
import { LocationModel } from "../models/LocationModel";
import { ILocationModel } from "../models/LocationModel/interfaces";
import { UserModel } from "../models/UserModel";
import { IUserModel, IUserModelFactory } from "../models/UserModel/interfaces";
import { UserModelFactory } from "../models/UserModel/UserModelFactory";
import { AnalyticsManager } from "../services/AnalyticsManager";
import { IAnalytics } from "../services/AnalyticsManager/interfaces";
import { ApiService } from "../services/ApiService";
import { IApiService } from "../services/ApiService/interfaces";
import { AuthService } from "../services/AuthService";
import { IAuthService } from "../services/AuthService/interfaces";
import { CoreApiService } from "../services/CoreApiService";
import { ICoreApiService } from "../services/CoreApiService/interfaces";
import { DbService } from "../services/DbService";
import { IDbService } from "../services/DbService/interfaces";
import { DrivingPlanDbService } from "../services/DrivingPlanDbService";
import { IDrivingPlanDbService } from "../services/DrivingPlanDbService/interfaces";
import { FirebaseAccessor } from "../services/FirebaseAccessor";
import { IFirebaseAccessor } from "../services/FirebaseAccessor/interfaces";
import { HttpService } from "../services/HttpService";
import { IHttpService } from "../services/HttpService/interfaces";
import { I18nService } from "../services/I18nService";
import { II18nService } from "../services/I18nService/interfaces";
import { NativeAppService } from "../services/NativeAppService";
import { INativeAppService } from "../services/NativeAppService/interfaces";
import { Settings } from "../services/Settings";
import { ISettings } from "../services/Settings/interfaces";
import { UserDbService } from "../services/UserDbService";
import { IUserDbService } from "../services/UserDbService/interfaces";
import { AppStore } from "../stores/AppStore";
import { IAppStore } from "../stores/AppStore/interfaces";
import { DrivingStore } from "../stores/DrivingStore";
import { IDrivingStore } from "../stores/DrivingStore/interfaces";
import { SettingsStore } from "../stores/SettingsStore";
import { ISettingsStore } from "../stores/SettingsStore/interfaces";
import { UiStore } from "../stores/UiStore";
import { IUiStore } from "../stores/UiStore/interfaces";
import { UserStore } from "../stores/UserStore";
import { IUserStore } from "../stores/UserStore/interfaces";
import {
  AcademyServiceModelSymbol,
  AnalyticsManagerSymbol,
  ApiServiceSymbol,
  AppStoreSymbol,
  AuthServiceSymbol,
  CalendarModelSymbol,
  CheckoutScreenModelSymbol,
  ClassEventModelSymbol,
  ConfirmationFormModelSymbol,
  ConfirmationScreenModelSymbol,
  CoreApiServiceSymbol,
  CoreConfirmationScreenModelSymbol,
  DatetimeSymbol,
  DbServiceSymbol,
  DemoFormModelSymbol,
  DrivingPlanDbServiceSymbol,
  DrivingStoreSymbol,
  EventModelFactorySymbol,
  EventsListScreenModelSymbol,
  FbDrivingExamEventModelSymbol,
  FbTheoryExamEventModelSymbol,
  FirebaseAccessorSymbol,
  HttpServiceSymbol,
  I18nServiceSymbol,
  InstructorsScreenModelSymbol,
  InversifyContextSymbol,
  LessonEventModelSymbol,
  LocationModelSymbol,
  NativeAppServiceSymbol,
  OrderModelSymbol,
  ReScheduleModelSymbol,
  ReservePayStoreSymbol,
  ScheduleModelSymbol,
  ServicesScreenModelSymbol,
  SettingsStoreSymbol,
  SettingsSymbol,
  SignInScreenModelSymbol,
  SignUpScreenModelSymbol,
  UiStoreSymbol,
  UserDbServiceSymbol,
  UserModelFactorySymbol,
  UserModelSymbol,
  UserSettingsStoreSymbol,
  UserStoreSymbol,
  GiftCardOrderModelSymbol,
} from "./symbols";
import { RequestCancellerModel } from "../components/bookings/OrderScreen/OrderForm/RequestCancellerModel";
import { UserSettingsStore } from "@/stores/UserStore/UserSettingsStore";
import { ReservePayStore } from "@/stores/ReservePay";

export const containerModule = new ContainerModule((bind) => {
  bind<interfaces.Context>(InversifyContextSymbol).toDynamicValue(
    (context) => context
  );
  bind<IUserStore>(UserStoreSymbol).to(UserStore).inSingletonScope();
  bind<IAppStore>(AppStoreSymbol).to(AppStore).inSingletonScope();
  bind<IAuthService>(AuthServiceSymbol).to(AuthService).inSingletonScope();
  bind<IFirebaseAccessor>(FirebaseAccessorSymbol)
    .to(FirebaseAccessor)
    .inSingletonScope();
  bind<ISettings>(SettingsSymbol).to(Settings).inSingletonScope();
  bind<IUserDbService>(UserDbServiceSymbol)
    .to(UserDbService)
    .inSingletonScope();
  bind<IDbService>(DbServiceSymbol).to(DbService).inSingletonScope();
  bind<ISettingsStore>(SettingsStoreSymbol)
    .to(SettingsStore)
    .inSingletonScope();
  bind<IApiService>(ApiServiceSymbol).to(ApiService).inSingletonScope();
  bind<ICoreApiService>(CoreApiServiceSymbol)
    .to(CoreApiService)
    .inSingletonScope();
  bind<INativeAppService>(NativeAppServiceSymbol)
    .to(NativeAppService)
    .inSingletonScope();
  bind<IHttpService>(HttpServiceSymbol).to(HttpService).inSingletonScope();
  bind<IDrivingPlanDbService>(DrivingPlanDbServiceSymbol)
    .to(DrivingPlanDbService)
    .inSingletonScope();
  bind<IUserModelFactory>(UserModelFactorySymbol)
    .to(UserModelFactory)
    .inSingletonScope();
  bind<IEventModelFactory>(EventModelFactorySymbol)
    .to(EventModelFactory)
    .inSingletonScope();
  bind<IDatetime>(DatetimeSymbol).to(Datetime).inSingletonScope();
  bind<IUiStore>(UiStoreSymbol).to(UiStore).inSingletonScope();
  bind<IDrivingStore>(DrivingStoreSymbol).to(DrivingStore).inSingletonScope();
  bind<II18nService>(I18nServiceSymbol).to(I18nService).inSingletonScope();
  bind<IAnalytics>(AnalyticsManagerSymbol)
    .to(AnalyticsManager)
    .inSingletonScope();
  bind<UserSettingsStore>(UserSettingsStoreSymbol)
    .to(UserSettingsStore)
    .inSingletonScope();

  bind<ISignInScreenModel>(SignInScreenModelSymbol)
    .to(SignInScreenModel)
    .inTransientScope();
  bind<ISignUpScreenModel>(SignUpScreenModelSymbol)
    .to(SignUpScreenModel)
    .inTransientScope();
  bind<IConfirmationFormModel>(ConfirmationFormModelSymbol)
    .to(ConfirmationFormModel)
    .inTransientScope();
  bind<IUserModel>(UserModelSymbol).to(UserModel).inTransientScope();
  bind<ILessonEventModel>(LessonEventModelSymbol)
    .to(LessonEventModel)
    .inTransientScope();
  bind<IFbTheoryExamEventModel>(FbTheoryExamEventModelSymbol)
    .to(FbTheoryExamEventModel)
    .inTransientScope();
  bind<IFbDrivingExamEventModel>(FbDrivingExamEventModelSymbol)
    .to(FbDrivingExamEventModel)
    .inTransientScope();
  bind<ICheckoutScreenModel>(CheckoutScreenModelSymbol)
    .to(CheckoutScreenModel)
    .inTransientScope();
  bind<ICalendarModel>(CalendarModelSymbol)
    .to(CalendarModel)
    .inTransientScope();
  bind<ICoreConfirmationScreenModel>(CoreConfirmationScreenModelSymbol)
    .to(CoreConfirmationScreenModel)
    .inTransientScope();
  bind<IScheduleModel>(ScheduleModelSymbol)
    .to(ScheduleModel)
    .inTransientScope();
  bind<IOrderModel>(OrderModelSymbol).to(OrderModel).inTransientScope();
  bind<RequestCancellerModel>(RequestCancellerModel)
    .to(RequestCancellerModel)
    .inSingletonScope();
  bind<ILocationModel>(LocationModelSymbol)
    .to(LocationModel)
    .inTransientScope();
  bind<IClassEventModel>(ClassEventModelSymbol)
    .to(ClassEventModel)
    .inTransientScope();
  bind<IServiceData>(AcademyServiceModelSymbol)
    .to(AcademyServiceModel)
    .inTransientScope();
  bind<IReScheduleModel>(ReScheduleModelSymbol)
    .to(ReScheduleModel)
    .inTransientScope();
  bind<IEventsListScreenModel>(EventsListScreenModelSymbol)
    .to(EventsListScreenModel)
    .inTransientScope();
  bind<IInstructorsScreenModel>(InstructorsScreenModelSymbol)
    .to(InstructorsScreenModel)
    .inTransientScope();
  bind<IServicesScreenModel>(ServicesScreenModelSymbol)
    .to(ServicesScreenModel)
    .inTransientScope();
  bind<IDemoFormModel>(DemoFormModelSymbol)
    .to(DemoFormModel)
    .inSingletonScope();
  bind<ReservePayStore>(ReservePayStoreSymbol)
    .to(ReservePayStore)
    .inSingletonScope();
  bind<IGiftCardOrderModel>(GiftCardOrderModelSymbol)
    .to(GiftCardOrderModel)
    .inSingletonScope();
});
